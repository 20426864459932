var render = function render(){var _vm=this,_c=_vm._self._c;return _c('chat-window',{staticClass:"chat-window",attrs:{"single-room":true,"rooms":_vm.rooms,"current-user-id":_vm.getUserId,"messages":_vm.messages,"height":"calc(70vh - 100px)","loading-rooms":false,"rooms-loaded":true,"messages-loaded":_vm.messagesLoaded,"show-footer":false,"message-actions":[],"show-reaction-emojis":false,"text-messages":{
    ROOMS_EMPTY: '',
    ROOM_EMPTY: 'Nada a ser exibido',
    NEW_MESSAGES: '',
    MESSAGE_DELETED: '',
    MESSAGES_EMPTY: '',
    CONVERSATION_STARTED: '',
    TYPE_MESSAGE: '',
    SEARCH: '',
    IS_ONLINE: '',
    LAST_SEEN: '',
    IS_TYPING: '',
  }},scopedSlots:_vm._u([{key:"emoji-picker",fn:function({  }){return [_c('emoji-picker',{ref:"emojiPicker",staticClass:"light",attrs:{"locale":"pt_BR"}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }