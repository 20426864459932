<template>
    <div>
      <title-bar :title-stack="titleStack" />
      <hero-bar :has-right-visible="false">
        Templates
      </hero-bar>
      <section class="section is-main-section">
        <b-message type="is-danger" v-if="!getTokenPermission && !fetchingDebugToken">
          <p class="has-text-centered">
            Você não tem permissão para sincronizar os templates. Verifique as permissões atribuídas ao token gerado na plataforma da Meta.
          </p>
        </b-message>
        <button @click="handleSyncTemplateButton"
            :loading="fetchingTemplates"
            :disabled="!getTokenPermission && !fetchingDebugToken"
            class="button is-success mb-2 mr-2 is-pulled-right">
            Sincronizar templates
        </button>
      </section>
      <section class="section is-main-section">
        <ModalTemplateMessage v-if="isComponentModalActive" :isComponentModalActive="isComponentModalActive" :updatingTemplate="viewingTemplate" v-on:updateTemplate="handleUpdateTemplate" v-on:createTemplate="handleAddTemplate" v-on:closeModal="closeModal"/>
        <b-loading v-if="fetchingTemplates" :is-full-page="true" v-model="fetchingTemplates" :can-cancel="false"></b-loading>
        <TemplatesList v-else :templates='allTemplates' v-on:updateTemplate="mountTemplate" v-on:removeTemplate="handleDeleteTemplate"/>
        <FabButton :hidden="!getTokenPermission && !fetchingDebugToken" v-on:addClick="handleAddClick" iconClass="fas fa-pen-fancy" title="Novo template" />
      </section>
    </div>
  </template>

<script>

import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import TemplatesList from '@/components/templates/TemplatesList'
import ModalTemplateMessage from '@/components/modals/ModalTemplateMessage'
import { mapActions, mapGetters } from 'vuex'
import FabButton from '@/components/FabButton'

export default {
  name: 'Templates',
  components: {
    HeroBar,
    TitleBar,
    TemplatesList,
    ModalTemplateMessage,
    FabButton
  },
  data () {
    return {
      isComponentModalActive: false,
      viewingTemplate: null
    }
  },
  computed: {
    ...mapGetters(['allTemplates', 'fetchingTemplates', 'debugToken', 'fetchingDebugToken']),
    titleStack () {
      return ['Cadastros', 'Templates']
    },
    getTokenPermission () {
      if (this.debugToken) {
        return this.debugToken.scopes.find(scope => scope === 'whatsapp_business_management')
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapActions(['fetchTemplates', 'syncTemplates', 'updateTemplate', 'fetchDebugToken', 'addTemplate', 'deleteTemplate']),
    mountTemplate (template) {
      this.viewingTemplate = template
      this.isComponentModalActive = true
    },
    closeModal () {
      this.viewingTemplate = null
      this.isComponentModalActive = false
    },
    async handleSyncTemplateButton () {
      try {
        await this.syncTemplates()
      } catch (error) {
        console.log(error)
        this.$buefy.dialog.alert({
          title: 'Erro',
          message: `Não foi possível sincronizar os templates! <br> ${error}`,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    async handleUpdateTemplate (template) {
      template.id = this.viewingTemplate.id
      await this.updateTemplate(template)
        .then(() => this.closeModal())
        .catch((error) => {
          this.$buefy.toast.open({
            message: `Não foi possível atualizar o template! <br> ${error.response.data.message}`,
            type: 'is-danger',
            duration: 5000,
            position: 'is-bottom-right',
            queue: false,
            containerClass: 'has-text-centered'
          })
        })
    },
    handleAddTemplate (template) {
      this.addTemplate(template)
        .then(() => {
          this.closeModal()
        })
        .catch((error) => {
          this.$buefy.toast.open({
            message: `Não foi possível adicionar o template! <br> ${error.response.data.message}`,
            type: 'is-danger',
            duration: 5000,
            position: 'is-bottom-right',
            queue: false,
            containerClass: 'has-text-centered'
          })
        })
    },
    handleAddClick () {
      this.mountTemplate(null)
    },
    handleDeleteTemplate (template) {
      try {
        this.deleteTemplate(template.id)
      } catch (error) {
        this.$buefy.dialog.alert({
          title: 'Erro',
          message: `Não foi possível excluir o template! <br> ${error.response.data.message}`,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    }
  },
  created () {
    this.fetchTemplates()
    this.fetchDebugToken()
  }
}
</script>
