import { render, staticRenderFns } from "./TemplatesList.vue?vue&type=template&id=6dc8e73f&scoped=true&"
import script from "./TemplatesList.vue?vue&type=script&lang=js&"
export * from "./TemplatesList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dc8e73f",
  null
  
)

export default component.exports