<template>
  <chat-window
    class="chat-window"
    :single-room="true"
    :rooms="rooms"
    :current-user-id="getUserId"
    :messages="messages"
    height="calc(70vh - 100px)"
    :loading-rooms="false"
    :rooms-loaded="true"
    :messages-loaded="messagesLoaded"
    :show-footer="false"
    :message-actions="[]"
    :show-reaction-emojis="false"
    :text-messages="{
      ROOMS_EMPTY: '',
      ROOM_EMPTY: 'Nada a ser exibido',
      NEW_MESSAGES: '',
      MESSAGE_DELETED: '',
      MESSAGES_EMPTY: '',
      CONVERSATION_STARTED: '',
      TYPE_MESSAGE: '',
      SEARCH: '',
      IS_ONLINE: '',
      LAST_SEEN: '',
      IS_TYPING: '',
    }"
  >
    <template #emoji-picker="{  }">
      <emoji-picker ref="emojiPicker" locale="pt_BR" class="light"></emoji-picker>
    </template>
  </chat-window>
</template>

<script>
import mixin from '../../utils/mixins'
import ChatWindow from 'vue-advanced-chat'
import { mapGetters } from 'vuex'

export default {
  name: 'TemplateChat',
  props: {
    messages: {
      type: Array,
      default: () => []
    },
    rooms: {
      type: Array,
      default: () => []
    }
  },
  mixins: [mixin],
  components: {
    ChatWindow
  },
  computed: {
    ...mapGetters(['getUserId'])
  },
  data () {
    return {
      messagesLoaded: true
    }
  }
}
</script>

<style lang="scss">
.vac-messages-container .vac-message-wrapper .vac-message-container {
  min-width: 200px !important;
}
</style>
