<template>
    <div>
      <div v-if="allTemplates.length > 0">
        <b-table
          :data="allTemplates"
          :bordered="false"
          :striped="true"
          :narrowed="false"
          :hoverable="true"
          :loading="false"
          :focusable="false"
          :mobile-cards="true"
          :paginated="true"
          scrollable
        >
          <b-table-column :numeric="true" field="id" label="ID" v-slot="props">
            {{ props.row.id }}
          </b-table-column>

          <b-table-column field="title" label="Título" v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column field="status" label="Status" v-slot="props">
            <b-tag type="is-success" outlined v-if="props.row.status === 'APPROVED'">{{ statuses.find(status => status.value === props.row.status).label }}</b-tag>
            <b-tag type="is-warning" outlined v-else-if="props.row.status === 'PENDING'">{{ statuses.find(status => status.value === props.row.status).label }}</b-tag>
            <b-tag type="is-danger" outlined v-else>{{ statuses.find(status => status.value === props.row.status).label }}</b-tag>
          </b-table-column>

          <b-table-column field="category" label="Categoria" v-slot="props">
            {{ categories.find(category => category.value === props.row.category).label }}
          </b-table-column>

          <b-table-column field="template_id" label="Id Meta" v-slot="props">
            {{ props.row.template_id }}
          </b-table-column>

          <b-table-column field="update_delete" label="" v-slot="props">
            <dropdown-tag :isDeleting="templateDeletingId === props.row.id">
              <dropdown-item-tag @click="updateTemplate(props.row)">Atualizar</dropdown-item-tag>
              <dropdown-item-tag @click="confirmTemplateDelete(props.row)">Excluir</dropdown-item-tag>
            </dropdown-tag>
          </b-table-column>

          <template #footer>
            <div class="has-text-left">
              Quantidade de registros {{ allTemplates.length }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </template>

<script>
import mixin from '../../utils/mixins'
import DropdownTag from '../commons/DropdownTag.vue'
import DropdownItemTag from '../commons/DropdownItemTag.vue'
import { Categories, Statuses } from '../../utils/constants'

export default {
  name: 'TemplatesList',
  components: {
    DropdownTag,
    DropdownItemTag
  },
  props: {
    templates: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      templateDeletingId: null,
      categories: Categories,
      statuses: Statuses
    }
  },
  mixins: [mixin],
  computed: {
    allTemplates: {
      get () {
        return this.templates
      },
      set () {
        // do nothing
      }
    }
  },
  methods: {
    updateTemplate (template) {
      this.$emit('updateTemplate', template)
    },
    confirmTemplateDelete (template) {
      this.$buefy.dialog.confirm({
        title: 'Excluir template',
        message: 'Deseja <b>excluir</b> este template?',
        confirmText: 'Excluir template',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.$emit('removeTemplate', template)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
