<template>
    <div>
      <section>
        <b-modal full-screen :active="isComponentModalActive" @close="closeModal">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ updatingTemplate ? 'Editar' : 'Criar' }} template</p>
            </header>
            <section class="modal-card-body" style="overflow-y: auto !important">
                  <div class="columns is-4 wrap">
                    <div class="column is-one-fifth box mx-2 sticky" style="box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)">
                      <b-field label="Nome">
                        <b-input v-on:input.native="adjustName" v-model="template.name" :readonly="updatingTemplate"></b-input>
                      </b-field>

                      <b-field label="Categoria">
                        <b-select placeholder="Selecione uma categoria" :disabled="template.status === 'APPROVED'" v-model="template.category" expanded>
                          <option key="MARKETING" value="MARKETING" >Marketing</option>
                          <option key="UTILITY" value="UTILITY">Utilidade</option>
                          <option key="AUTHENTICATION" value="AUTHENTICATION">Autenticação</option>
                        </b-select>
                      </b-field>

                      <b-field v-if="updatingTemplate" label="Status">
                        <b-tag type="is-success" outlined v-if="template.status === 'APPROVED'">{{ statuses.find(status => status.value === template.status).label }}</b-tag>
                        <b-tag type="is-warning" outlined v-else-if="template.status === 'PENDING'">{{ statuses.find(status => status.value === template.status).label }}</b-tag>
                        <b-tag type="is-danger" outlined v-else>{{ statuses.find(status => status.value === template.status).label }}</b-tag>
                      </b-field>
                    </div>

                    <div class="column box mx-2 main" style="box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)">
                      <b-field label="Componentes">
                        <div class="column">
                          <div class="field">
                            <b-field>
                              <template #label>
                                Cabeçalho
                                <small class="is-small has-text-weight-light has-text-grey-light">Opcional</small>
                              </template>
                              <div class="field is-grouped is-grouped-centered">
                                <div class="control">
                                  <button id="button-NONE" class="button is-success is-outlined" @click="selectHeader('NONE')">Nenhum</button>
                                </div>
                                <div class="control">
                                  <button id="button-TEXT" class="button is-success is-outlined" @click="selectHeader('TEXT')">Texto</button>
                                </div>
                                <div class="control">
                                  <button id="button-DOCUMENT" class="button is-success is-outlined"  type="is-success" @click="selectHeader('DOCUMENT')">Documento</button>
                                </div>
                                <div class="control">
                                  <button id="button-IMAGE" class="button is-success is-outlined"  type="is-success" @click="selectHeader('IMAGE')">Imagem</button>
                                </div>
                                <div class="control">
                                  <button id="button-VIDEO" class="button is-success is-outlined"  type="is-success" @click="selectHeader('VIDEO')">Vídeo</button>
                                </div>
                              </div>
                            </b-field>
                          </div>
                          <div v-if="header.format !== 'NONE'">
                            <b-field v-if="header.format === 'TEXT'" label="Texto">
                              <b-input v-model="header.text" maxlength="60" type="input" expanded></b-input>
                              <div class="field has-addons">
                                <div class="control">
                                  <b-tooltip label="Adicionar variável" type="is-success" append-to-body>
                                    <b-button type="is-success" outlined @click="handleAddVariable('header')">
                                      <b-icon icon="plus"></b-icon>
                                    </b-button>
                                  </b-tooltip>
                                </div>
                              </div>
                            </b-field>
                            <b-field v-else-if="header.format === 'LOCATION'" label="Texto">
                              <b-input v-model="header.text" type="textarea" expanded></b-input>
                            </b-field>
                            <b-field v-else-if="header.format !== 'TEXT' && header.format !== 'LOCATION'">
                              <template #label>
                                {{ header.format === 'DOCUMENT' ? 'Documento' : header.format === 'IMAGE' ? 'Imagem' : 'Vídeo' }}
                              </template>
                              <b-upload :accept="getFileFilter" v-model="file" class="file-label">
                                <span class="file-cta">
                                    <b-icon class="file-icon" icon="upload"></b-icon>
                                    <span class="file-label">Clique para selecionar</span>
                                </span>
                                <span class="file-name" v-if="file">
                                    {{ file.name }}
                                </span>
                              </b-upload>
                              <b-button v-if="header.example.header_handle.length > 0" class="button is-info mx-4" outlined @click="() => viewFile(header.example.header_handle[0])">Visualizar arquivo</b-button>
                            </b-field>
                          </div>

                          <div v-if="header.format === 'TEXT' && header.example.header_text && header.example.header_text.length > 0">
                            <b-field>
                              <template #label>
                                Exemplo variáveis
                                <b-tooltip type="is-success" size="is-large" multilined label="Exemplo de como a variável será preenchida">
                                  <b-icon type ="is-primary" size="is-small" icon="help-circle"></b-icon>
                                </b-tooltip>
                              </template>
                              <b-field v-for="(example, index) in header.example.header_text" :key="index">
                                <p class="control">
                                    <span class="button is-static">{{ getVariable(index + 1) }}</span>
                                </p>
                                <b-input v-model="header.example.header_text[index]" type="input"></b-input>
                              </b-field>
                            </b-field>
                          </div>

                          <div v-if="header.format === 'TEXT' && header.default_values && header.default_values.length > 0" class="mt-4">
                            <b-field>
                              <template #label>
                                Valores padrões
                                <b-tooltip type="is-success" size="is-large" multilined label="Valores que serão preenchidos automaticamente na tela no envio do template. Informe um texto livre ou selecione uma das variáveis disponíveis">
                                  <b-icon type ="is-primary" size="is-small" icon="help-circle"></b-icon>
                                </b-tooltip>
                              </template>
                              <b-field v-for="(default_value, index) in header.default_values" :key="index">
                                <p class="control">
                                    <span class="button is-static is-success is-light">{{ getVariable(index + 1) }}</span>
                                </p>
                                <b-autocomplete
                                  v-model="header.default_values[index]"
                                  :data="autocompleteOptions"
                                  placeholder="Informe um texto livre ou selecione uma das sugestôes"
                                  open-on-focus
                                ></b-autocomplete>
                              </b-field>
                            </b-field>
                          </div>

                          <b-field label="Corpo da mensagem" class="mt-4" addons>
                            <div class="container">
                              <div class="textarea-container">
                                <textarea id="component-body-text" class="textarea" rows="5" v-model="body.text" placeholder="Digite seu texto aqui"></textarea>
                                <div class="button-container">
                                  <b-tooltip label="Adicionar variável" type="is-success" append-to-body>
                                    <b-button type="is-success" outlined @click="handleAddVariable('body')">
                                      <b-icon icon="plus"></b-icon>
                                    </b-button>
                                  </b-tooltip>
                                  <b-tooltip label="Emojis" class="mt-4" type="is-success" append-to-body>
                                    <b-dropdown
                                    :focusable="false"
                                    custom
                                    position="is-top-left">
                                      <template #trigger>
                                        <b-button type="is-success" outlined>
                                          <b-icon icon="emoticon-happy"></b-icon>
                                        </b-button>
                                      </template>
                                      <b-dropdown-item custom aria-role="menu-item" paddingless>
                                        <div style="width: 320px; height: 350px; border-radius: 4px;">
                                          <emoji-picker ref="emojiPicker" locale="pt_BR" class="light" @emoji-click="handleEmojiPicker"></emoji-picker>
                                        </div>
                                      </b-dropdown-item>
                                    </b-dropdown>
                                  </b-tooltip>
                                </div>
                              </div>
                            </div>
                          </b-field>

                          <div v-if="body.example.body_text[0].length > 0">
                            <b-field grouped group-multiline>
                              <template #label>
                                Exemplo variáveis
                                <b-tooltip type="is-success" size="is-large" multilined label="Exemplo de como a variável será preenchida">
                                  <b-icon type ="is-primary" size="is-small" icon="help-circle"></b-icon>
                                </b-tooltip>
                              </template>
                              <b-field v-for="(example, index) in body.example.body_text[0]" class="my-2" :key="index" addons>
                                <p class="control is-expanded">
                                    <span class="button is-static">{{ getVariable(index + 1) }}</span>
                                </p>
                                <b-input v-model="body.example.body_text[0][index]" type="input" expanded></b-input>
                              </b-field>
                              <br>
                            </b-field>
                          </div>

                          <div v-if="body.default_values && body.default_values.length > 0" class="mt-4">
                            <b-field grouped group-multiline>
                              <template #label>
                                Valores padrões
                                <b-tooltip type="is-success" size="is-large" multilined label="Valores que serão preenchidos automaticamente na tela no envio do template. Informe um texto livre ou selecione uma das variáveis disponíveis">
                                  <b-icon type ="is-primary" size="is-small" icon="help-circle"></b-icon>
                                </b-tooltip>
                              </template>
                              <b-field v-for="(default_value, index) in body.default_values" class="my-2" :key="index" addons>
                                <p class="control">
                                    <span class="button is-static is-success is-light">{{ getVariable(index + 1) }}</span>
                                </p>
                                <b-autocomplete
                                  v-model="body.default_values[index]"
                                  :data="autocompleteOptions"
                                  placeholder="Informe um texto livre ou selecione uma das sugestôes"
                                  open-on-focus
                                ></b-autocomplete>
                              </b-field>
                            </b-field>
                          </div>

                          <b-field class="mt-4">
                            <template #label>
                              Rodapé
                              <small class="is-small has-text-weight-light has-text-grey-light">Opcional</small>
                            </template>
                            <b-input type="input" maxlength="60" v-model="footer.text" expanded></b-input>
                          </b-field>

                          <b-field>
                            <template #label>
                              Botões
                              <small class="is-small has-text-weight-light has-text-grey-light">Opcional</small>
                            </template>
                            <b-dropdown>
                              <template #trigger>
                                <b-button icon-left="plus" class="button is-info" outlined>
                                  <span>Adicionar botão</span>
                                </b-button>
                              </template>
                              <b-dropdown-item custom>
                                <p class="is-size-6 has-text-weight-bold">
                                  Botões de resposta rápida
                                </p>
                              </b-dropdown-item>
                              <b-dropdown-item @click="handleAddButton('QUICK_REPLY')">Personalizado</b-dropdown-item>
                              <b-dropdown-item custom>
                                <p class="is-size-6 has-text-weight-bold">
                                  Botões de chamada para ação
                                </p>
                              </b-dropdown-item>
                              <b-dropdown-item @click="handleAddButton('URL')">Acessar site</b-dropdown-item>
                              <b-dropdown-item @click="handleAddButton('PHONE_NUMBER')">Ligar</b-dropdown-item>
                              <b-dropdown-item @click="handleAddButton('COPY_CODE')">Copiar código de oferta</b-dropdown-item>
                            </b-dropdown>
                          </b-field>
                          <b-field class="box" v-if="getButtonsQuickReply.length > 0" label="Botões de resposta rápida">
                            <b-table
                            :data="getButtonsQuickReply"
                            :paginated="false"
                            hoverable
                            draggable
                            @dragstart="dragstart"
                            @drop="drop"
                            @dragover="dragover"
                            @dragleave="dragleave"
                            @mouseenter="mouseenter"
                            @mouseleave="mouseleave"
                            >
                              <b-table-column>
                                <b-icon icon="dots-grid" />
                              </b-table-column>
                              <b-table-column field="text" label="Texto" width="30%" v-slot="props">
                                <b-input v-model="props.row.text" type="input"></b-input>
                              </b-table-column>
                              <b-table-column field="function" label="Funcionalidade" v-slot="props">
                                <b-field grouped group-multiline>
                                  <b-select placeholder="Selecione uma opção" v-model="props.row.function" expanded>
                                    <option value="PAD" key="PAD">Seguir o fluxo normal de atendimento</option>
                                    <option value="PADI" key="PADI">Seguir o fluxo normal de atendimento (para integrações)</option>
                                    <option value="REA" key="REA">Redirecionar para atendente</option>
                                    <option value="RED" key="RED">Redirecionar para departamento</option>
                                    <option value="TAG" key="TAG">Adicionar tag ao contato</option>
                                    <option value="CLO" key="CLO">Encerrar atendimento</option>
                                  </b-select>
                                  <b-select v-if="props.row.function === 'RED' || props.row.function === 'REA'" v-on:change.native="setAttendants" placeholder="Selecione um departamento" v-model="props.row.departmentId" expanded>
                                    <option v-for="department in filteredDepartments" :value="department.id" :key="department.name">
                                      {{ department.name }}
                                    </option>
                                  </b-select>
                                  <b-select v-if="props.row.function === 'REA'" placeholder="Selecione um atendente" v-model="props.row.attendantId" expanded>
                                    <option v-for="attendant in attendants" :value="attendant.id" :key="attendant.name">
                                      {{ attendant.name }}
                                    </option>
                                  </b-select>
                                  <b-select v-if="props.row.function === 'TAG'" placeholder="Selecione uma tag" v-model="props.row.tagId" expanded>
                                    <option v-for="tag in getTags" :value="tag.id" :key="tag.title">
                                      {{ tag.title }}
                                    </option>
                                  </b-select>
                                </b-field>
                              </b-table-column>
                              <b-table-column v-slot="props">
                                <b-button type="is-danger" icon-left="close" @click="handleRemoveButton(props.row.index)"></b-button>
                              </b-table-column>
                            </b-table>
                          </b-field>
                          <b-field class="box" v-if="getButtonsCallToAction.length > 0" label="Botões de chamada para ação">
                            <b-table
                            :data="getButtonsCallToAction"
                            :paginated="false"
                            hoverable
                            draggable
                            @dragstart="dragstart"
                            @drop="drop"
                            @dragover="dragover"
                            @dragleave="dragleave"
                            @mouseenter="mouseenter"
                            @mouseleave="mouseleave"
                            >
                              <b-table-column>
                                <b-icon icon="dots-grid" />
                              </b-table-column>
                              <b-table-column v-slot="props">
                                <b-field grouped>
                                  <b-field label="Texto do campo">
                                    <b-input v-model="props.row.text" type="input" expanded></b-input>
                                  </b-field>
                                  <b-field v-if="props.row.type === 'URL'" label="URL">
                                    <b-input v-model="props.row.url" type="input" expanded></b-input>
                                  </b-field>
                                  <b-field v-if="props.row.type === 'PHONE_NUMBER'" label="Telefone">
                                    <b-input v-model="props.row.phone_number" type="input" expanded></b-input>
                                  </b-field>
                                  <b-field v-if="props.row.type === 'COPY_CODE'" label="Exemplo de código">
                                    <b-input v-model="props.row.example[0]" type="input" expanded></b-input>
                                  </b-field>
                                </b-field>
                              </b-table-column>
                              <b-table-column v-slot="props">
                                <b-button type="is-danger" icon-left="close" @click="handleRemoveButton(props.row.index)"></b-button>
                              </b-table-column>
                            </b-table>
                          </b-field>
                        </div>
                      </b-field>
                    </div>

                    <div class="column is-one-fifth mx-2 sticky" style="box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)">
                      <TemplateChat :messages="messages" :rooms="rooms" />
                    </div>
                  </div>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-pulled-right" type="button" @click="closeModal">Fechar</button>
              <button class="button is-pulled-right is-success" type="button" @click="saveModal">Gravar</button>
            </footer>
          </div>
        </b-modal>
      </section>
    </div>
  </template>

<script>
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import TemplateChat from '../templates/TemplateChat.vue'
import mixin from '../../utils/mixins'
import { mapActions, mapGetters } from 'vuex'
import { enumContentType, Statuses, Categories, zeepsVariables } from '../../utils/constants'

export default {
  name: 'ModalTemplateMessage',
  components: {
    TemplateChat
  },
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    updatingTemplate: {
      type: Object,
      required: false
    }
  },
  mixins: [mixin],
  data () {
    return {
      messages: [],
      messagesLoaded: true,
      loadingMessages: false,
      rooms: [],
      messagesPage: 1,
      urlPhotoShowing: '',
      template: {
        id: 0,
        name: '',
        category: '',
        status: '',
        title: '',
        components: [],
        template_buttons: [],
        created_at: new Date(),
        updated_at: new Date()
      },
      buttons: [],
      header: {
        text: '',
        format: 'NONE',
        example: {
          header_handle: []
        },
        default_values: []
      },
      body: {
        text: '',
        format: '',
        example: {
          body_text: [[]]
        },
        default_values: []
      },
      footer: {
        text: '',
        format: '',
        example: {}
      },
      file: null,
      draggingRow: null,
      draggingRowIndex: null,
      componentType: enumContentType,
      categories: Categories,
      statuses: Statuses,
      autocompleteOptions: Object.keys(zeepsVariables)
    }
  },
  watch: {
    buttons: {
      handler (val) {
        this.buttons.map(b => {
          switch (b.function) {
            case 'REA':
              b.attendantId = b.attendantId || null
              b.departmentId = b.departmentId || null
              b.tagId = null
              break
            case 'RED':
              b.attendantId = null
              b.tagId = null
              break
            case 'TAG':
              b.attendantId = null
              b.departmentId = null
              break
            case 'CLO':
            case 'PAD':
            default:
              b.attendantId = null
              b.departmentId = null
              b.tagId = null
              break
          }
        })
      },
      deep: true
    },
    header: {
      handler (val) {
        if (val.format === 'TEXT') {
          this.handleVariables('header')
          this.messages = [this.adaptMessage(this.template)]
          this.rooms = [this.adaptTicketToRoom(this.template)]
        }
      },
      deep: true
    },
    body: {
      handler (val) {
        this.handleVariables('body')
        this.messages = [this.adaptMessage(this.template)]
        this.rooms = [this.adaptTicketToRoom(this.template)]
      },
      deep: true
    },
    footer: {
      handler (val) {
        this.handleVariables('footer')
        this.messages = [this.adaptMessage(this.template)]
        this.rooms = [this.adaptTicketToRoom(this.template)]
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['getUserId', 'allAttendantsEnabled', 'allDepartments', 'allTags']),
    attendants () {
      return this.allAttendantsEnabled
    },
    filteredDepartments () {
      return this.allDepartments
    },
    getTags () {
      return this.allTags.filter(t => t.enabled)
    },
    hasDefaultWithIntegration () {
      return this.buttons.filter(b => b.function === 'PADI').length > 0
    },
    getButtonsQuickReply () {
      return this.buttons.filter(b => b.type === 'QUICK_REPLY')
    },
    getButtonsCallToAction () {
      return this.buttons.filter(b => b.type !== 'QUICK_REPLY')
    },
    getFileFilter () {
      switch (this.header.format) {
        case 'DOCUMENT':
          return 'application/pdf'
        case 'VIDEO':
          return 'video/*'
        case 'IMAGE':
          return 'image/*'
        default:
          return '*'
      }
    }
  },
  created () {
    this.fetchAttendants()
    this.fetchDepartments()
    this.fetchTags()
    if (this.updatingTemplate) {
      this.template = this.updatingTemplate
      this.buttons = this.template.template_buttons.map((button, index) => {
        return {
          text: button.button_id,
          attendantId: button.attendant_id,
          function: button.button_function,
          departmentId: button.department_id,
          tagId: button.tag_id,
          type: button.button_type,
          url: button.url,
          phone_number: button.phone_number,
          index: index,
          example: button.example || []
        }
      })

      if (this.template.components.find(c => c.type === this.componentType.HEADER)) {
        const header = this.template.components.find(c => c.type === this.componentType.HEADER)
        const example = {}
        if (header.format === 'TEXT') {
          const values = header.examples.map(m => m.value)
          example.header_text = values
        } else {
          const values = header.examples.map(m => m.value)
          example.header_handle = values
        }
        this.header.format = header.format
        this.header.text = header.text
        this.header.example = example
        this.header.default_values = header.default_values.length > 0 ? header.default_values.map(d => d.default_value) : this.header.format === 'TEXT' && this.header.example.header_text && this.header.example.header_text.length > 0 ? this.header.example.header_text : ['']
        this.template.components = this.template.components.filter(c => c.type !== this.componentType.HEADER)
        this.template.components.push({
          ...header
        })
      } else {
        this.header.format = 'NONE'
      }

      if (this.template.components.find(c => c.type === this.componentType.BODY)) {
        const body = this.template.components.find(c => c.type === this.componentType.BODY)
        const examples = {
          body_text: [body.examples.map(m => m.value)]
        }
        this.body.format = body.format
        this.body.text = body.text
        this.body.example = examples
        this.body.default_values = body.default_values.length > 0 ? body.default_values.sort((a, b) => a.order - b.order).map(d => d.default_value) : this.body.example.body_text[0].length > 0 ? JSON.parse(JSON.stringify(this.body.example.body_text[0])) : ['']
      }

      if (this.template.components.find(c => c.type === this.componentType.FOOTER)) {
        const footer = this.template.components.find(c => c.type === this.componentType.FOOTER)
        this.footer.format = footer.format
        this.footer.text = footer.text
        this.footer.example = footer.example
      }

      this.rooms = [this.adaptTicketToRoom(this.template)]
      this.messages = [this.adaptMessage(this.template)]
    }
  },
  mounted () {
    if (this.header && this.header.format) {
      this.selectHeader(this.header.format)
    }
  },
  methods: {
    ...mapActions(['fetchAttendants', 'fetchDepartments', 'fetchTags']),
    handleVariables (field) {
      switch (field) {
        case 'header':
          if (this.header.format === 'TEXT') {
            const regex = /{{\d{1,}}}/gm
            const matches = this.header.text.match(regex)
            if (matches) {
              if (matches.length > 1) {
                this.$buefy.toast.open({
                  message: 'Você só pode usar uma variável no cabeçalho',
                  type: 'is-danger'
                })
                this.header.text = this.header.text.replace(/\{\{(\d{2,}|[2-9])\}\}/g, '')
                const uniqueRegex = /{{[1]}}/g
                const uniqueMatches = this.header.text.match(uniqueRegex)
                if (uniqueMatches && uniqueMatches.length > 1) {
                  const index = this.header.text.lastIndexOf('{{1}}')
                  this.header.text = this.header.text.slice(0, index) + this.header.text.slice(index + 6)
                }
                if (this.header.example.header_text.length > 1) {
                  this.header.example.header_text = this.header.example.header_text.slice(0, 1)
                }
              } else if (matches.length === 1) {
                if (!this.header.example.header_text || this.header.example.header_text.length === 0) {
                  this.header.example.header_text = ['']
                }
                if (!this.header.default_values || this.header.default_values.length === 0) {
                  this.header.default_values = ['']
                }
              }
            } else {
              if (this.header.example.header_text.length > 0) {
                this.header.example.header_text = []
              }

              if (this.header.default_values.length > 0) {
                this.header.default_values = []
              }
            }

            if (this.header.text.length > 60) {
              this.$buefy.toast.open({
                message: 'O cabeçalho deve ter no máximo 60 caracteres',
                type: 'is-danger'
              })
              this.header.text = this.header.text.slice(0, 60)
            }

            if (this.header.text.length === 0) {
              this.header.text = ''
            }
          }
          break
        case 'body': {
          const regex = /{{\d{1,}}}/gm
          const matches = this.body.text.match(regex)
          if (matches) {
            if (matches.length !== this.body.example.body_text[0].length) {
              if (this.body.example.body_text[0].length < matches.length) {
                for (let i = 0; i < matches.length; i++) {
                  const match = matches[i]
                  const index = match.replace(/{{/g, '').replace(/}}/g, '')

                  if (index > matches.length) {
                    this.body.text = this.body.text.replace(match, `{{${i + 1}}}`)
                  }

                  if (this.body.example.body_text[0][index - 1] === undefined) {
                    this.body.example.body_text[0].push('')
                  }

                  if (this.body.default_values[index - 1] === undefined) {
                    this.body.default_values.push('')
                  }
                }
              } else {
                this.body.example.body_text[0].forEach((text, index) => {
                  const match = matches.find(m => m.includes(`{{${index + 1}}}`))
                  if (!match) {
                    this.body.example.body_text[0].splice(index, 1)
                    this.body.default_values.splice(index, 1)
                  }
                })
              }
            }
          } else {
            if (this.body.example.body_text[0].length > 0) {
              this.body.example.body_text[0] = []
            }
            if (this.body.default_values.length > 0) {
              this.body.default_values = []
            }
          }
        }
          break
        case 'footer':
        default:
          break
      }
    },
    getVariable (variable) {
      return `{{${variable}}}`
    },
    dragstart (payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.row.index
      payload.event.dataTransfer.effectAllowed = 'copyMove'
    },
    dragover (payload) {
      payload.event.dataTransfer.dropEffect = 'move'
      payload.event.target.closest('tr').classList.add('is-selected')
      payload.event.preventDefault()
    },
    dragleave (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      payload.event.preventDefault()
    },
    drop (payload) {
      payload.event.target.closest('tr').classList.remove('is-selected')
      const droppedOnRowIndex = payload.row.index
      this.buttons.splice(this.draggingRowIndex, 1)
      this.buttons.splice(droppedOnRowIndex, 0, this.draggingRow)
      this.buttons = this.buttons.map((b, index) => {
        return {
          ...b,
          index
        }
      })
      payload.event.preventDefault()
    },
    mouseenter (payload, event) {
      event.target.closest('tr').classList.add('is-selected')
      event.target.closest('tr').style.cursor = 'grab'
    },
    mouseleave (payload, event) {
      event.target.closest('tr').classList.remove('is-selected')
      event.target.closest('tr').style.cursor = 'default'
    },
    selectHeader (format) {
      this.header.format = format
      this.header.text = ''

      if (format === 'TEXT') {
        this.header.example = {
          header_text: ['']
        }
      } else {
        this.header.example = {
          header_handle: []
        }
      }

      if (this.updatingTemplate) {
        const header = this.template.components.find(c => c.type === this.componentType.HEADER)
        if (header && header.format === format) {
          const example = {}
          if (header.format === 'TEXT') {
            const values = header.examples.map(m => m.value)
            example.header_text = values
          } else {
            const values = header.examples.map(m => m.value)
            example.header_handle = values
          }
          this.header.example = example
          this.header.text = header.text
        }
      }

      const element = document.getElementById(`button-${format}`)
      if (element) element.classList.remove('is-outlined')

      const buttons = ['NONE', 'TEXT', 'DOCUMENT', 'IMAGE', 'VIDEO']
      buttons.forEach(b => {
        if (b !== format) {
          const element = document.getElementById(`button-${b}`)
          if (element) element.classList.add('is-outlined')
        }
      })
    },
    closeModal () {
      this.$emit('closeModal')
    },
    adaptTicketToRoom (template) {
      return {
        ...template,
        roomId: template.template_id || template.id,
        roomName: template.name || '',
        currentUserId: this.getUserId,
        unreadCount: 0,
        lastMessage: {
          content: `Ticket: ${template.id}`,
          timestamp: `${this.formatShortDate(template.created_at)}`,
          date: template.created_at
        },
        users: [
          {
            _id: this.getUserId,
            username: 'John Doe'
          },
          {
            _id: template.id,
            username: 'John Snow'
          }
        ],
        typingUsers: []
      }
    },
    adaptMessage (template) {
      let message = ''
      const file = []
      const fileType = new Map([
        ['DOCUMENT', 'pdf'],
        ['IMAGE', 'image'],
        ['VIDEO', 'video']
      ])

      if (this.header) {
        if (this.header.format === 'TEXT') {
          message = '*' + this.getModifiedMessage(this.header.text, this.header.example.header_text) + '*'
        } else if (this.header.format === 'DOCUMENT' || this.header.format === 'IMAGE' || this.header.format === 'VIDEO') {
          if (this.header.example && this.header.example.header_handle && this.header.example.header_handle.length) {
            file.push({
              name: 'My File',
              type: fileType.get(this.header.format),
              url: this.header.example.header_handle[0]
            })
          }
        }
      }

      if (this.body) {
        if (message.length > 0) {
          message += '\n\n'
        }
        message += this.getModifiedMessage(this.body.text, this.body.example.body_text[0])
      }

      if (this.footer && this.footer.text && this.footer.text.length > 0) {
        if (message.length > 0) {
          message += '\n\n'
        }
        message += this.getModifiedMessage(this.footer.text)
      }

      return {
        _id: template.template_id || template.id,
        content: message,
        timestamp: `${this.formatShortDate(Date.now())}`,
        type: 'text',
        senderId: this.getUserId,
        user: {
          _id: this.getUserId,
          username: 'John Doe'
        },
        files: file
      }
    },
    getModifiedMessage (message, examples = []) {
      let newMessage = message.replace(/<br>/g, '\n')
      for (let i = 0; i < examples.length; i++) {
        const example = examples[i]
        const regex = new RegExp(`{{[${i + 1}]}}`, 'g')
        newMessage = newMessage.replace(regex, example || `{{${i + 1}}}`)
      }

      return newMessage
    },
    handleEmojiPicker (event) {
      const element = document.getElementById('component-body-text')
      if (element) {
        element.focus()
        const index = element.selectionStart
        const text = element.value
        const before = text.substring(0, index)
        const after = text.substring(index, text.length)
        element.value = before + event.detail.unicode + after
        this.body.text = element.value
        element.selectionStart = index + event.detail.unicode.length
        element.selectionEnd = index + event.detail.unicode.length
        this.template.components.find(c => c.type === this.componentType.BODY).text = element.value
      }
    },
    handleAddVariable (field) {
      switch (field) {
        case 'header':
          if (this.header.format === 'TEXT') {
            this.header.example.header_text.push('')
            const matches = this.header.text.match(/{{\d{1,}}}/gm)
            this.header.text += `{{${matches ? matches.length + 1 : 1}}}`
            this.header.default_values.push('')
          }
          break
        case 'body': {
          this.body.example.body_text[0].push('')
          const matches = this.body.text.match(/{{\d{1,}}}/gm)
          this.body.text += `{{${matches ? matches.length + 1 : 1}}}`
          this.body.default_values.push('')
          break
        }
        case 'footer':
          break
        default:
          break
      }
    },
    viewFile (url) {
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      a.download = 'download.pdf'
      a.click()
    },
    saveModal () {
      if (this.template.name.length === 0) {
        this.$buefy.toast.open({
          message: 'O nome do template é obrigatório',
          type: 'is-danger'
        })
        return
      }

      const components = []

      if (this.header && this.header.format !== 'NONE') {
        const header = {
          type: this.componentType.HEADER,
          text: this.header.text,
          format: this.header.format,
          example: this.header.example,
          default_values: this.header.default_values
        }

        components.push(header)
      }

      if (this.body && this.body.text.length > 0) {
        const body = {
          type: this.componentType.BODY,
          text: this.body.text,
          example: this.body.example,
          default_values: this.body.default_values
        }
        components.push(body)
      } else {
        this.$buefy.dialog.alert({
          title: 'Erro',
          message: 'O corpo da mensagem é obrigatório',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }

      if (this.footer && this.footer.text.length > 0) {
        const footer = {
          type: this.componentType.FOOTER,
          text: this.footer.text
        }
        components.push(footer)
      }

      if (this.buttons && this.buttons.length > 0) {
        const buttons = this.buttons.map(b => {
          return {
            button_id: b.text,
            button_function: b.function,
            button_type: b.type,
            url: b.url,
            phone_number: b.phone_number,
            attendant_id: b.attendantId,
            department_id: b.departmentId,
            tag_id: b.tagId,
            example: b.example,
            type: b.type,
            text: b.text
          }
        })

        components.push({
          type: 'BUTTONS',
          buttons: buttons
        })
      }

      const formData = new FormData()
      if (this.file) {
        formData.append('file', this.file)
      }

      const newTemplate = {
        name: this.template.name,
        category: this.template.category,
        language: 'pt_BR',
        components: components,
        allow_category_change: true
      }

      formData.append('name', this.template.name)
      formData.append('category', this.template.category)
      formData.append('language', 'pt_BR')
      formData.append('components', JSON.stringify(components))
      formData.append('allow_category_change', true)

      if (this.updatingTemplate) {
        newTemplate.template_id = this.updatingTemplate.template_id
        newTemplate.id = this.updatingTemplate.id
        formData.append('template_id', this.updatingTemplate.template_id)
        formData.append('id', this.updatingTemplate.id)
        this.$emit('updateTemplate', formData)
      } else {
        this.$emit('createTemplate', formData)
      }
    },
    getFunctionHelp (buttonFunction) {
      switch (buttonFunction) {
        case 'PAD':
          return 'Esta opção deve ser usada para o envio de mensagens pelo Chat. A resposta será processada como uma mensagem comum.'
        case 'PADI':
          return 'Esta opção deve ser usada para o envio de mensagens agendadas com perguntas, para que o click de um botão seja interpretado como resposta.'
        default:
          return ''
      }
    },
    handleAddButton (buttonType) {
      this.buttons.push({
        text: '',
        function: 'PAD',
        departmentId: null,
        attendantId: null,
        tagId: null,
        type: buttonType,
        index: this.buttons.length,
        example: []
      })
      this.template.components.find(c => c.type === 'BUTTONS').buttons.push({
        text: '',
        type: buttonType
      })
    },
    handleRemoveButton (index) {
      this.buttons.splice(index, 1)
      if (this.template.components.find(c => c.type === 'BUTTONS')) {
        this.template.components.find(c => c.type === 'BUTTONS').buttons.splice(index, 1)
      } else {
        return
      }
      this.buttons = this.buttons.map((b, index) => {
        return {
          ...b,
          index
        }
      })
    },
    adjustName () {
      if (this.template.name.length > 60) {
        this.template.name = this.template.name.substring(0, 60)
      }

      this.template.name = this.template.name.replace(/ /g, '_')
      this.template.name = this.template.name.toLowerCase()
    }
  }
}
</script>

  <style lang="scss" scoped>
  .modal .animation-content .modal-card {
    overflow: visible !important;
  }
  .modal-card-body {
    overflow: visible !important;
  }
  .modal-card-foot {
    justify-content: flex-end;
  }
  .vac-room-item-open {
    border-color: aquamarine;
    border-width: 1px;
    border-style: solid;
    // animation: blinker 1.5s linear infinite;
  }
  .vac-container-scroll__margin-bottom {
    margin-bottom: 60px;
  }
  .vac-box-footer__hidden {
    display: none !important;
  }
  .select-attendant-div {
    background-color: aquamarine;
  }
  @keyframes blinker {
    50% {
      opacity: 30%;
    }
  }

  html {
  box-sizing: border-box;
}

.wrap {
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
}
.sticky {
  position: sticky;
  top: 0;
}
.main {
  flex: 1 0 0;
  margin-top: 0.75em;
}
.box {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
}
.emoji-picker-container {
  white-space: pre-wrap;
}

emoji-picker {
  width: 100%;
  height: 100%;
}

.container {
    display: flex;
    align-items: center;
}

.textarea-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
}

.textarea {
    flex: 1;
    margin-right: 10px;
    width: 100%;
}

.button-container {
    display: flex;
    flex-direction: column;
}
  </style>
